// @flow

import {type HoursMinutes, validateHoursMinutes, type Weekday, validateWeekday} from "../Time"
import {type DeviceUUID, validateDeviceUUID} from "../Device"
import {validateString, type Validator, shapeOf, arrayOf, invariant} from "../Common"

/**
 * Represents a scheduled event.
 */
export type IScheduleEvent = {
  name: ScheduleEventName,
  type: ScheduleEventType,
  from: HoursMinutes,
  to: HoursMinutes,
  devices: DeviceUUID[],
  occursWeekly: ScheduleEventOccursWeekly,
  occursMonthly: ScheduleEventOccursMonthly,
  occursDate: ScheduleEventOccursDate
}

/**
 * The name of the event.
 */
export type ScheduleEventName = string

/**
 * The event type.
 */
export type ScheduleEventType = "shifts" | "classification"

/** @todo what is this? */
export type ScheduleEventOccursWeekly = Array<Weekday>

/** @todo what is this? */
export type ScheduleEventOccursMonthly = Array<string>

/** @todo what is this? */
export type ScheduleEventOccursDate = Array<string>

/**
 * Validates a Schedule Event Name
 */
export function validateScheduleEventName(input: string): ScheduleEventName {
  return validateString(input)
}

/**
 * Validates a Schedule Event Type
 */
export function validateScheduleEventType(input: string): ScheduleEventType {
  validateString(input)
  invariant(input === "shifts" || input === "classification", "Event type must be shifts or classification.")
  return input
}

/**
 * Validates a Schedule Event Occurs Weekly
 */
export const validateScheduleEventOccursWeekly: Validator<ScheduleEventOccursWeekly> = arrayOf(validateWeekday)

/**
 * Validates a Schedule Event Occurs Monthly
 */
export const validateScheduleEventOccursMonthly: Validator<ScheduleEventOccursMonthly> = arrayOf(validateString)

/**
 * Validates a Schedule Event Occurs Date
 */
export const validateScheduleEventOccursDate: Validator<ScheduleEventOccursDate> = arrayOf(validateString)

/**
 * Validates a Schedule Event.
 */
export const validateScheduleEvent: Validator<IScheduleEvent> = shapeOf({
  name: validateScheduleEventName,
  type: validateScheduleEventType,
  from: validateHoursMinutes,
  to: validateHoursMinutes,
  devices: arrayOf(validateDeviceUUID),
  occursWeekly: validateScheduleEventOccursWeekly,
  occursMonthly: validateScheduleEventOccursMonthly,
  occursDate: validateScheduleEventOccursDate
})
