// @flow
import {type IDevice, type DeviceTag} from "../Device"
import {type IPlantStateActivities} from "./PlantStateActivities"
import {type IWorkday, type ITimezone} from "../Time"
import {validateString} from "../Common"

/**
 * A plant, belonging to a tenant.
 */
export type IPlant = {
  name: PlantName,
  devices: IDevice[],
  deviceTags: DeviceTag[],
  stateActivities: IPlantStateActivities,
  workday: IWorkday,
  timezone: ITimezone
}

/**
 * The url-friendly name for a plant.
 */
export type PlantName = string

/**
 * Validate a plant name.
 * @todo this needs refinement.
 */
export function validatePlantName(input: string): PlantName {
  return validateString(input)
}
