// @flow

import {type Username, validateUsername} from "./User"
import {type TenantName, validateTenantName} from "../Tenant"
import {type PermissionName, validatePermissionName} from "./Permission"
import {type Validator, shapeOf, arrayOf, validateString} from "../Common"

/**
 * An authentication token, represented as a string.
 */
export type AuthToken = string

/**
 * A separate token used to request a new `AuthToken`.
 */
export type RefreshToken = string

/**
 * The name of an auth token issuer.
 */
export type TokenIssuer = string

/**
 * A client id used when obtaining a token.
 */
export type ClientID = string

/**
 * A client secret used when obtaining a token.
 */
export type ClientSecret = string

/**
 * The raw, decoded token (with short property names).
 */
export type AuthTokenContent = {
  iss: string,
  exp: string,
  sub: Username,
  tnt: TenantName,
  prm: PermissionName[]
}

/**
 * Validates an auth token.
 */
export const validateAuthToken: Validator<AuthToken> = validateString

/**
 * Validate a decoded auth token.
 */
export const validateAuthTokenContent: Validator<AuthTokenContent> = shapeOf({
  iss: validateString,
  exp: validateString,
  sub: validateUsername,
  tnt: validateTenantName,
  prm: arrayOf(validatePermissionName)
})
