// @flow

import {type PermissionName, validatePermissionName} from "./Permission"
import {type UUID, type Validator, validateUUID, validateString, invariant, shapeOf, arrayOf} from "../Common"

/**
 * Represents an access role which can be assigned to
 * one or more users.
 */
export type IRole = {
  id: RoleID,
  name: RoleName,
  permissions: PermissionName[]
}

/**
 * The ID of a role.
 */
export type RoleID = UUID

/**
 * The name of a role.
 */
export type RoleName = string

/**
 * Validate a Role ID.
 */
export function validateRoleID(input: string): RoleID {
  return validateUUID(input)
}

/**
 * Validate a Role Name.
 */
export function validateRoleName(input: string): RoleName {
  validateString(input)
  invariant(input.length > 0, "Expected a non empty role name")
  return input
}

/**
 * Validate the permissions for a role.
 */
export const validateRolePermissions: Validator<Array<PermissionName>> = arrayOf(validatePermissionName)

/**
 * Validate a Role.
 */
export const validateRole: Validator<IRole> = shapeOf({
  id: validateRoleID,
  name: validateRoleName,
  permissions: validateRolePermissions
})
