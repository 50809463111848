// @flow
import {type ISOTimestamp, validateISOTimestamp} from "../Time"
import {type IAlertWatcher, validateAlertWatcher} from "./AlertWatcher"
import {type UUID, validateUUID, validateString, type Validator, shapeOf} from "../Common"

/**
 * Represents a comment on an alert.
 */
export type IAlertComment = {
  id: AlertCommentID,
  timestamp: ISOTimestamp,
  body: AlertCommentBody,
  author: IAlertWatcher
}

/**
 * The unique id for the comment.
 */
export type AlertCommentID = UUID

/**
 * The (text only) comment body.
 */
export type AlertCommentBody = string

/**
 * Validates an Alert Comment ID
 */
export function validateAlertCommentID(input: string): AlertCommentID {
  return validateUUID(input)
}
/**
 * Validates an Alert Comment Body (the content of the comment.)
 */
export function validateAlertCommentBody(input: string): AlertCommentBody {
  return validateString(input)
}

/**
 * Validate an Alert Comment.
 */
export const validateAlertComment: Validator<IAlertComment> = shapeOf({
  id: validateAlertCommentID,
  timestamp: validateISOTimestamp,
  body: validateAlertCommentBody,
  author: validateAlertWatcher
})
