// @flow
import {type IAlertDevice, validateAlertDevice} from "./AlertDevice"
import {type IAlertWatcher, validateAlertWatcher} from "./AlertWatcher"
import {type HoursMinutes, validateHoursMinutes} from "../Time"
import {type Validator, validateString, validateBoolean, shapeOf, arrayOf} from "../Common"

/**
 * A rule for alert watchers.
 */
export type IAlertWatcherRule = {
  id: AlertWatcherRuleID,
  name: AlertWatcherRuleName,
  enabled: AlertWatcherRuleEnabled,
  type: AlertWatcherRuleType[],
  devices: IAlertDevice[],
  watchers: IAlertWatcher[],
  from: HoursMinutes,
  to: HoursMinutes
}

/**
 * The unique ID for an alert watcher rule.
 */
export type AlertWatcherRuleID = string

/**
 * A name for an alert watcher rule.
 */
export type AlertWatcherRuleName = string

/**
 * Whether the rule is enabled..
 */
export type AlertWatcherRuleEnabled = boolean

/**
 * The type of an alert watcher rule.
 * @todo clarification
 */
export type AlertWatcherRuleType = string

/**
 * Validates an Alert Watcher Rule ID.
 */
export function validateAlertWatcherRuleID(input: string): AlertWatcherRuleID {
  return validateString(input)
}

/**
 * Validates an Alert Watcher Rule Rule.
 */
export function validateAlertWatcherRuleName(input: string): AlertWatcherRuleName {
  return validateString(input)
}

/**
 * Validates an Alert Watcher Rule enabled field.
 */
export function validateAlertWatcherRuleEnabled(input: boolean): AlertWatcherRuleEnabled {
  return validateBoolean(input)
}

/**
 * Validates an Alert Watcher Rule Type.
 */
export function validateAlertWatcherRuleType(input: string): AlertWatcherRuleType {
  return validateString(input)
}

/**
 * Validates an Alert Watcher Rule from time.
 */
export const validateAlertWatcherRuleFrom: Validator<HoursMinutes> = validateHoursMinutes

/**
 * Validates an Alert Watcher Rule to time.
 */
export const validateAlertWatcherRuleTo: Validator<HoursMinutes> = validateHoursMinutes

/**
 * Validates an Alert Watcher Rule.
 */
export const validateAlertWatcherRule: Validator<IAlertWatcherRule> = shapeOf({
  id: validateAlertWatcherRuleID,
  name: validateAlertWatcherRuleName,
  enabled: validateAlertWatcherRuleEnabled,
  type: arrayOf(validateAlertWatcherRuleType),
  devices: arrayOf(validateAlertDevice),
  watchers: arrayOf(validateAlertWatcher),
  from: validateAlertWatcherRuleFrom,
  to: validateAlertWatcherRuleTo
})
