// @flow
import {type PlantName, validatePlantName} from "../Plant"
import {type PartnerName, validatePartnerName} from "./Partner"
import {type IBranding, validateBranding} from "./Branding"
import {type AccessRightName, validateAccessRightName} from "./AccessRight"
import {type Validator, shapeOf, arrayOf} from "../Common/Validation"

/**
 * A Licence applied to a Plant.
 */
export type ILicence = {
  plantName: PlantName,
  partnerName: PartnerName,
  branding: IBranding,
  accessRights: AccessRightName[]
}

/**
 * Validate a Licence.
 */
export const validateLicence: Validator<ILicence> = shapeOf({
  plantName: validatePlantName,
  partnerName: validatePartnerName,
  branding: validateBranding,
  accessRights: arrayOf(validateAccessRightName)
})
