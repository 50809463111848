// @flow
import {type Units, type NativeUnits} from "../Metrics/Units"

/**
 * A particular kind of data item associated with a device.
 */
export type IDeviceDataItem = {
  name: DeviceDataItemName,
  category: DeviceDataItemCategory,
  type: DeviceDataItemType,
  sub_type?: DeviceDataItemSubType,
  type_ns?: DeviceDataItemTypeNS,
  sub_type_ns?: DeviceDataItemTypeNS,
  units?: DeviceDataItemUnits,
  native_units?: DeviceDataItemNativeUnits,
  coordinate_system?: DeviceDataItemCoordinateSystem,
  device_serial_number?: DeviceDataItemDeviceSerialNumber
}

/**
 * The name of the data item.
 * name is provided as an additional human readable identifier for this
 * data item in addition to the id.
 */
export type DeviceDataItemName = string

/**
 * @todo what is this?
 */
export type DeviceDataItemTypeNS = string

/**
 * The type of data being measured.
 * Examples of types are POSITION, VELOCITY, ANGLE, BLOCK,
 * and ROTARY_VELOCITY.
 */
export type DeviceDataItemType = string

/**
 * A sub-categorization of the data item type.
 * For example, the subType of POSITION can be ACTUAL or COMMANDED.
 * Not all type attributes have a subType.
 */
export type DeviceDataItemSubType = string

/**
 * Specifies the kind of information provided by a data item.
 *
 * *SAMPLE*
 *
 * > A `SAMPLE` is the reading of the value of a continuously variable or analog
 * > data value. A continuous value can be measured at any point-in-time and will
 * > always produce a result. An example of a continuous data value is the
 * > position of the Linear X Axis.
 * > The data provided for a `SAMPLE` category data item is always a floating point
 * > number or integers that have an infinite number of possible values. This is
 * > different from a state or discrete type data item that has a limited number of
 * > possible values. A data item of category `SAMPLE` MUST also provide the
 * > units attribute.
 *
 * *EVENT*
 *
 * > An `EVENT` is a data item representing a discrete piece of information from the
 * > piece of equipment. `EVENT` does not have intermediate values that vary over
 * > time, as does `SAMPLE`. An `EVENT` is information that, when provided at any
 * > specific point in time, represents the current state of the piece of equipment.
 * > There are two types of `EVENT`: those representing state, with two or more
 * > discrete values, and those representing messages that contain plain text data.
 * > An example of a state type `EVENT` is the value of the data item
 * > `DOOR_STATE`, which can be `OPEN`, `UNLATCHED`, or `CLOSED`. (Note: No
 * > other values are valid to represent the value of `DOOR_STATE`.)
 * > An example of a message type `EVENT` is the value for a data item PROGRAM.
 * > The value representing PROGRAM can be any valid string of characters.
 *
 *  *CONDITION*
 *
 * > A `CONDITION` is a data item that communicates information about the health
 * > of a piece of equipment and its ability to function. A valid value for a data
 * > item in the category `CONDITION` can be one of `NORMAL`, `WARNING`, or
 * > `FAULT`.
 * > A data item of category `CONDITION` MAY report multiple values
 * > (`CONDITION`) at one time whereas a data item of category `SAMPLE` or
 * > `EVENT` can only have a single value at any one point in time.
 *
 */
export type DeviceDataItemCategory = "SAMPLE" | "EVENT" | "CONDITION"

/**
 * The unit of measurement for the reported value of the data item.
 * Data items in the Sample category MUST report the standard units
 * for the measured values.
 */
export type DeviceDataItemUnits = Units

/**
 * The native units of measurement for the reported value of the data item.
 */
export type DeviceDataItemNativeUnits = NativeUnits

/**
 * The values reported by a piece of equipment for some types of data will be associated to a
 * specific positioning measurement system used by the equipment. The coordinate system
 * attribute MAY be used to specify the coordinate system used for the measured value.
 * The coordinate system attribute is used by a client software application to interpret the
 * spatial relationship between values reported by a piece of equipment.
 */
export type DeviceDataItemCoordinateSystem =
  // An unchangeable coordinate system that has machine zero as its origin
  | "WORK"
  // The coordinate system that represents the working area for a
  // particular workpiece whose origin is shifted within the
  // MACHINE coordinate system. If the WORK coordinates are
  // not currently defined in the piece of equipment, the
  // MACHINE coordinates will be used.
  | "MACHINE"

export type DeviceDataItemDeviceSerialNumber = string

/**
 * Validate a Device Data Item.
 * @todo implementation!
 */
export function validateDeviceDataItem(input: Object): IDeviceDataItem {
  return input
}
