// @flow
import {type Validator, validateString, shapeOf} from "../Common"
import {type ISOTimestamp, validateISOTimestamp} from "../Time"
import {type ITicketWatcher, validateTicketWatcher} from "./TicketWatcher"

/**
 * A comment on a ticket.
 */
export type ITicketComment = {
  body: TicketCommentBody,
  timestamp: ISOTimestamp,
  author: ITicketWatcher
}

/**
 * The text content for a comment on a ticket.
 */
export type TicketCommentBody = string

/**
 * Validates a Ticket Comment Body.
 */
export function validateTicketCommentBody(input: string): TicketCommentBody {
  return validateString(input)
}

/**
 * Validates a Ticket Comment
 */
export const validateTicketComment: Validator<ITicketComment> = shapeOf({
  body: validateTicketCommentBody,
  timestamp: validateISOTimestamp,
  author: validateTicketWatcher
})
