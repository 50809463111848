// @flow

import {type IBranding, validateBranding} from "./Branding"
import {type Validator, validateString, shapeOf} from "../Common/Validation"

/**
 * The name of a Partner.
 */
export type PartnerName = string

/**
 * A Vimana partner.
 */
export type IPartner = {
  partnerName: PartnerName,
  branding: IBranding
}

/**
 * Validates a Partner Name.
 */
export const validatePartnerName: Validator<PartnerName> = validateString

/**
 * Validates a Partner object.
 */
export const validatePartner: Validator<IPartner> = shapeOf({
  partnerName: validatePartnerName,
  branding: validateBranding
})
