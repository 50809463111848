// @flow
import {type IPlant} from "../Plant"
import {validateString} from "../Common"

/**
 * A tenant of vimana. Tenants have one or more plants.
 */
export type ITenant = {
  name: TenantName,
  plants: IPlant[]
}

/**
 * The URL-friendly name of a tenant.
 */
export type TenantName = string

/**
 * Validates a tenant name.
 */
export function validateTenantName(input: string): TenantName {
  return validateString(input)
}
