// @flow
import {validateString, invariant, validateBoolean, type Validator, shapeOf} from "../Common"

/**
 * Represents a possible state of a device.
 */
export type IPlantState = {
  id: PlantStateID,
  name: PlantStateName,
  show: PlantStateShouldShow
}

/**
 * The unique ID of a possible device state.
 */
export type PlantStateID =
  | "producing"
  | "standby"
  | "setup"
  | "shutdown"
  | "downtime.planned"
  | "downtime.unplanned"
  | "data-unavailable"

/**
 * The human friendly label for a device state.
 */
export type PlantStateName = string

/**
 * Indicates whether the plant state should be shown.
 * @todo clarification, shown where?
 */
export type PlantStateShouldShow = boolean

/**
 * Get the plant state with the given id
 * from an array of plant states.
 * @throws {Error} if no such state exists.
 */
export function getPlantState(states: IPlantState[], id: PlantStateID) {
  for (let i = 0; i < states.length; i++) {
    const item = states[i]
    if (item.id === id) {
      return item
    }
  }
  throw new Error(`Could not find a PlantState with id: ${id}`)
}

/**
 * Validate a Plant State ID.
 */
export function validatePlantStateID(input: string): PlantStateID {
  invariant(
    input === "producing" ||
      input === "standby" ||
      input === "setup" ||
      input === "shutdown" ||
      input === "downtime.planned" ||
      input === "downtime.unplanned" ||
      input === "data-unavailable",
    "Expected a valid plant state id."
  )
  return input
}

/**
 * Validates a Plant State Name.
 */
export function validatePlantStateName(input: string): PlantStateName {
  return validateString(input)
}

/**
 * Validate a Plant State Should Show value.
 */
export function validatePlantStateShouldShow(input: boolean): PlantStateShouldShow {
  return validateBoolean(input)
}

/**
 * Validate a Plant State.
 */
export const validatePlantState: Validator<IPlantState> = shapeOf({
  id: validatePlantStateID,
  name: validatePlantStateName,
  show: validatePlantStateShouldShow
})
