// @flow

/**
 * A function that validates that the given input
 * conforms to the right type and format.
 * @throws {Error} if the input is invalid.
 */
export type Validator<T> = T => T

/**
 * Ensures the given input is truthy, or throws an `InvariantViolation` error.
 */
export function invariant<T>(input: T, message: string = "Invariant Failed"): T {
  if (!input) {
    const error = new Error(message)
    // istanbul ignore next
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(error, invariant)
    }
    error.name = "InvariantViolation"
    throw error
  }
  return input
}

/**
 * Validate a boolean value.
 */
export function validateBoolean(input: boolean): boolean {
  invariant(typeof input === "boolean", "Expected true or false.")
  return input
}

/**
 * Validate a numeric value.
 */
export function validateNumber(input: number): number {
  invariant(typeof input === "number" && !Number.isNaN(input), "Expected a valid number")
  return input
}

/**
 * Validate a string value.
 */
export function validateString(input: string): string {
  invariant(typeof input === "string", "Expected a string")
  return input
}

/**
 * Validate a Date object.
 */
export function validateDate(input: Date): Date {
  invariant(input instanceof Date, "Expected a Date")
  return input
}

/**
 * Validate an Object.
 */
export function validateObject<T: Object>(input: T): T {
  invariant(typeof input === "object" && input !== null, "Expected an Object")
  return input
}

/**
 * Create a validator which matches exactly the given value.
 */
export function literal<T: boolean | string | number | null | void>(match: T): Validator<T> {
  return (input: T): T => {
    invariant(input === match, `Expected exactly ${String(match)}`)
    return input
  }
}

/**
 * Create a validator for an optional T.
 * Allows only T or undefined or null.
 */
export function optional<T>(validator: Validator<T>): Validator<?T> {
  return (input: ?T): ?T => {
    if (input !== undefined && input !== null) {
      validator(input)
    }
    return input
  }
}

/**
 * Create an array validator.
 */
export function arrayOf<T>(validator: Validator<T>): Validator<Array<T>> {
  return (input: Array<T>): Array<T> => {
    invariant(Array.isArray(input), "Expected an array")
    let lastIndex = 0
    try {
      for (let i = 0; i < input.length; i++) {
        lastIndex = i
        validator(input[i])
      }
      return input
    } catch (e) {
      const {message} = e
      const error = new Error(`Invalid array element: ${lastIndex}\n\t${message}`)
      error.name = "InvariantViolation"
      // istanbul ignore next
      if (typeof Error.captureStackTrace === "function") {
        Error.captureStackTrace(error, shapeOf)
      }
      throw error
    }
  }
}

/**
 * Create a validator for objects of the given shape.
 */
// eslint-disable-next-line
export function shapeOf<T: Object>(shape: $ObjMap<T, <V>(V) => Validator<V>>): Validator<T> {
  // $LegacyFlowIssue
  const keys = Object.keys(shape)
  return (input: T): T => {
    invariant(typeof input === "object" && input !== null, "Expected an object")
    let lastKey = ""
    try {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        lastKey = key
        // $LegacyFlowIssue
        const validator = shape[key]
        validator(input[key])
      }
      return input
    } catch (e) {
      const {message} = e
      const error = new Error(`Invalid Property: ${lastKey}\n\t${message}`)
      error.name = "InvariantViolation"
      // istanbul ignore next
      if (typeof Error.captureStackTrace === "function") {
        Error.captureStackTrace(error, shapeOf)
      }
      throw error
    }
  }
}

/**
 * Lazily create a validator. Useful for working around cyclic
 * dependency issues.
 */
export function lazy<T>(reveal: () => Validator<T>): Validator<T> {
  let revealed
  return (input: T): T => {
    if (revealed === undefined) {
      revealed = reveal()
    }
    return revealed(input)
  }
}

// eslint-disable-next-line no-unused-vars
declare function union<A>(Validator<A>): Validator<A>
// eslint-disable-next-line no-redeclare
declare function union<A, B>(Validator<A>, Validator<B>): Validator<A | B>
// eslint-disable-next-line no-redeclare
declare function union<A, B, C>(Validator<A>, Validator<B>, Validator<C>): Validator<A | B | C>
// eslint-disable-next-line no-redeclare
declare function union<A, B, C, D>(Validator<A>, Validator<B>, Validator<C>, Validator<D>): Validator<A | B | C | D>
// eslint-disable-next-line no-redeclare
declare function union<A, B, C, D, E>(
  Validator<A>,
  Validator<B>,
  Validator<C>,
  Validator<D>,
  Validator<E>
): Validator<A | B | C | D | E>
// eslint-disable-next-line no-redeclare
declare function union<A, B, C, D, E, F>(
  Validator<A>,
  Validator<B>,
  Validator<C>,
  Validator<D>,
  Validator<E>,
  Validator<F>
): Validator<A | B | C | D | E | F>
// eslint-disable-next-line no-redeclare
declare function union<A, B, C, D, E, F, G>(
  Validator<A>,
  Validator<B>,
  Validator<C>,
  Validator<D>,
  Validator<E>,
  Validator<F>,
  Validator<G>
): Validator<A | B | C | D | E | F | G>
/**
 * Create a validator which accepts any of the given validators.
 */
// eslint-disable-next-line no-redeclare
export function union<T>(...validators: Array<Validator<T>>): Validator<T> {
  return (input: T): T => {
    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i]
      try {
        return validator(input)
      } catch (e) {} // eslint-disable-line no-empty
    }
    throw new Error("Expected value to match at least one item in union.")
  }
}
// eslint-disable-next-line no-unused-vars
declare function intersect<A>(Validator<A>): Validator<A>
// eslint-disable-next-line no-redeclare
declare function intersect<A, B>(Validator<A>, Validator<B>): Validator<A & B>
// eslint-disable-next-line no-redeclare
declare function intersect<A, B, C>(Validator<A>, Validator<B>, Validator<C>): Validator<A & B & C>
// eslint-disable-next-line no-redeclare
declare function intersect<A, B, C, D>(Validator<A>, Validator<B>, Validator<C>, Validator<D>): Validator<A & B & C & D>
// eslint-disable-next-line no-redeclare
declare function intersect<A, B, C, D, E>(
  Validator<A>,
  Validator<B>,
  Validator<C>,
  Validator<D>,
  Validator<E>
): Validator<A & B & C & D & E>

/**
 * Create a validator which intersects all of the given validators.
 */
// eslint-disable-next-line no-redeclare
export function intersect<T>(...validators: Array<Validator<T>>): Validator<T> {
  return (input: T): T => {
    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i]
      validator(input)
    }
    return input
  }
}
