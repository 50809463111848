// @flow

import {type PlantName, validatePlantName} from "../Plant"
import {type PermissionName, validatePermissionName} from "./Permission"
import {
  type EmailAddress,
  type UUID,
  validateUUID,
  shapeOf,
  invariant,
  validateEmailAddress,
  optional,
  arrayOf,
  lazy,
  validateString,
  validateBoolean,
  type Validator
} from "../Common"
import {type RoleID, validateRoleID} from "./Role"

/**
 * A registered Vimana user.
 */
export type IUser = {
  id: UserID,
  username: Username,
  profile: void | IUserProfile,
  isEnabled: UserIsEnabled,
  tags: UserTag[],
  permissions: PermissionName[],
  roles: RoleID[],
  plants: PlantName[]
}

/**
 * The ID for a user.
 */
export type UserID = UUID

/**
 * The name a user uses to login to their account.
 */
export type Username = string

/**
 * Indicates whether a user account is enabled or not.
 */
export type UserIsEnabled = boolean

/**
 * A tag which can be applied to one or more users.
 */
export type UserTag = string

/**
 * The profile for a particular user.
 */
export type IUserProfile = {
  name: IUserFullName,
  email: EmailAddress
}

/**
 * The full name of a particular user.
 */
export type IUserFullName = {
  first: UserFirstName,
  last: UserLastName
}

/**
 * The first name(s) for a user.
 */
export type UserFirstName = string

/**
 * The last name(s) for a user.
 */
export type UserLastName = string

/**
 * Validate a UserID
 */
export function validateUserID(input: string): UserID {
  return validateUUID(input)
}

/**
 * Validate a Username
 * @fixme this needs refining.
 */
export function validateUsername(input: string): Username {
  validateString(input)
  invariant(input.length > 0, "Expected a non-empty username")
  return input
}

/**
 * Validate a UserIsEnabled
 */
export function validateUserIsEnabled(input: boolean): UserIsEnabled {
  return validateBoolean(input)
}

/**
 * Validate a UserTag
 * @fixme this needs refining.
 */
export function validateUserTag(input: string): UserTag {
  return validateString(input)
}

/**
 * Validate a user's first name.
 */
export function validateUserFirstName(input: string): UserFirstName {
  validateString(input)
  invariant(input.length < 100, "Expected first name to be less than 100 characters.")
  return input
}

/**
 * Validate a user's last name.
 */
export function validateUserLastName(input: string): UserLastName {
  validateString(input)
  invariant(input.length < 100, "Expected last name to be less than 100 characters.")
  return input
}

/**
 * Validate a user's full name.
 */
export const validateUserFullName: Validator<IUserFullName> = shapeOf({
  first: validateUserFirstName,
  last: validateUserLastName
})

/**
 * Validate a UserProfile.
 */
export const validateUserProfile: Validator<IUserProfile> = shapeOf({
  name: validateUserFullName,
  email: validateEmailAddress
})

/**
 * Validate a User
 */
export const validateUser: Validator<IUser> = lazy(() =>
  shapeOf({
    id: validateUserID,
    username: validateUsername,
    profile: optional(validateUserProfile),
    isEnabled: validateUserIsEnabled,
    tags: arrayOf(validateUserTag),
    permissions: arrayOf(validatePermissionName),
    roles: arrayOf(validateRoleID),
    plants: arrayOf(validatePlantName)
  })
)
