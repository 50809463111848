// @flow
import {type Color, type Validator, validateString, validateBoolean, validateColor, shapeOf} from "../Common"

/**
 * Represents a tag of alert.
 */
export type IAlertTag = {
  id: AlertTagID,
  enabled: AlertTagEnabled,
  color: AlertTagColor,
  value: AlertTagValue
}

/**
 * The unique ID for the alert tag.
 */
export type AlertTagID = string

/**
 * Indicates whether an alert tag is enabled or not.
 */
export type AlertTagEnabled = boolean

/**
 * The color associated with an alert tag.
 */
export type AlertTagColor = Color

/**
 * The value associated with an alert tag.
 * @todo what does this value actually represent?
 */
export type AlertTagValue = string

/**
 * Validates an Alert Tag ID.
 */
export function validateAlertTagID(input: string): AlertTagID {
  return validateString(input)
}

/**
 * Validates an Alert Type Enabled value.
 */
export function validateAlertTagEnabled(input: boolean): AlertTagEnabled {
  return validateBoolean(input)
}

/**
 * Validates an Alert Tag Color.
 */
export function validateAlertTagColor(input: string): AlertTagColor {
  return validateColor(input)
}

/**
 * Validates an Alert Tag Value.
 */
export function validateAlertTagValue(input: string): AlertTagValue {
  return validateString(input)
}

/**
 * Validates an Alert Tag.
 */
export const validateAlertTag: Validator<IAlertTag> = shapeOf({
  id: validateAlertTagID,
  enabled: validateAlertTagEnabled,
  color: validateAlertTagColor,
  value: validateAlertTagValue
})
