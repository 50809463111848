// @flow
import {type DeviceTag, type DeviceUUID, validateDeviceUUID, validateDeviceTag} from "../Device"
import {type Validator, shapeOf, literal, union} from "../Common"

/**
 * Represents a device or group of devices associated with a Ticket.
 */
export type ITicketDevice = TicketDeviceUUID | TicketDeviceTag

/**
 * Represents a device associated with a Ticket.
 */
export type TicketDeviceUUID = {
  type: "UUID",
  value: DeviceUUID
}

/**
 * Represents a group of devices associated with a Ticket
 */
export type TicketDeviceTag = {
  type: "TAG",
  value: DeviceTag
}

/**
 * Validates a Ticket Device UUID.
 */
export const validateTicketDeviceUUID: Validator<TicketDeviceUUID> = shapeOf({
  type: literal("UUID"),
  value: validateDeviceUUID
})

/**
 * Validates a Ticket Device Tag.
 */
export const validateTicketDeviceTag: Validator<TicketDeviceTag> = shapeOf({
  type: literal("TAG"),
  value: validateDeviceTag
})

/**
 * Validate a Ticket Device.
 */
export const validateTicketDevice: Validator<ITicketDevice> = union(validateTicketDeviceUUID, validateTicketDeviceTag)
