// @flow
import {validateString, type Validator, shapeOf} from "../Common/Validation"

/**
 * The name of an access right.
 */
export type AccessRightName = string

/**
 * A description of an access right.
 */
export type AccessRightDescription = string

/**
 * Access Rights describe the features made available via a Licence.
 */
export type IAccessRight = {
  name: AccessRightName,
  description: AccessRightDescription
}

/**
 * Validate the name of an access right.
 */
export const validateAccessRightName: Validator<AccessRightName> = validateString

/**
 * Validate the description of an access right.
 */
export const validateAccessRightDescription: Validator<AccessRightDescription> = validateString

/**
 * Validates an Access Right.
 */
export const validateAccessRight: Validator<IAccessRight> = shapeOf({
  name: validateAccessRightName,
  description: validateAccessRightDescription
})
