// @flow
import {type Validator, validateString, shapeOf} from "../Common/Validation"

/**
 * The name of a vimana theme.
 */
export type ThemeName = string

/**
 * Represents branding information for a Partner.
 */
export type IBranding = {
  themeName: ThemeName
}

/**
 * Validates a theme name.
 */
export const validateThemeName: Validator<ThemeName> = validateString

/**
 * Validates a Branding object.
 */
export const validateBranding: Validator<IBranding> = shapeOf({
  themeName: validateThemeName
})
