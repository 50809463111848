// @flow
import {type ISOTimestamp, validateISOTimestamp} from "../Time"
import {type UUID, validateUUID, validateString, type Validator, shapeOf} from "../Common"

/**
 * Represents a holiday in a schedule.
 */
export type IHoliday = {
  id: HolidayID,
  name: HolidayName,
  validityStart: ISOTimestamp,
  validityEnd: ISOTimestamp
}

/**
 * The ID of a holiday
 */
export type HolidayID = UUID

/**
 * The name of a holiday.
 */
export type HolidayName = string

/**
 * Validates a Holiday ID.
 */
export function validateHolidayID(input: string): HolidayID {
  return validateUUID(input)
}

/**
 * Validates a Holiday Name.
 */
export function validateHolidayName(input: string): HolidayName {
  return validateString(input)
}

/**
 * Validates a Holiday.
 */
export const validateHoliday: Validator<IHoliday> = shapeOf({
  id: validateHolidayID,
  name: validateHolidayName,
  validityStart: validateISOTimestamp,
  validityEnd: validateISOTimestamp
})
