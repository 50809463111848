import {IDeviceDataItem} from "../Device"
import {SelectValueType} from "./Common"

export type ConditionClassifyType = {
  conditionLevel?: SelectValueType,

  nativeCode?: boolean,
  nativeCodeOperator?: SelectValueType,
  nativeCodeValues?: string[],

  conditionText?: boolean,
  conditionTextOperator?: SelectValueType,
  conditionTextValues?: string[],

  conditionType?: boolean,
  conditionTypeOperator?: SelectValueType,
  conditionTypeDataItems?: IDeviceDataItem[]
}

export type MessageClassifyType = {
  nativeCode?: boolean,
  nativeCodeOperator?: SelectValueType,
  nativeCodeValues?: string[],

  conditionText?: boolean,
  conditionTextOperator?: SelectValueType,
  conditionTextValues?: string[],

  dataItem?: IDeviceDataItem
}
