// @flow
import {type IDeviceDataItem, validateDeviceDataItem} from "./DeviceDataItem"
import {type Validator, validateString, validateBoolean, invariant, shapeOf, arrayOf} from "../Common"

/**
 * Represents a particular machine within a plant.
 */
export type IDevice = {
  uuid: DeviceUUID,
  name: DeviceName,
  enabled: DeviceEnabled,
  allowProducing: DeviceAllowProducing,
  currentCycleClassify: DeviceCurrentCycleClassify,
  tags: DeviceTag[],
  performanceParameters: IDevicePerformanceParameters,
  hasRealtimeData: DeviceHasRealtimeData,
  deviceType: DeviceType
}

/**
 * An identifier for a device, unique within a plant.
 */
export type DeviceUUID = string

/**
 * A human-friendly name for an individual device in a plant.
 */
export type DeviceName = string

/**
 * Whether this device allows producing (to be set manually?)
 */
export type DeviceAllowProducing = boolean

/**
 * Whether this device has realtime data
 */
export type DeviceHasRealtimeData = boolean

/**
 * Whether this device is enabled or not.
 */
export type DeviceEnabled = boolean

/**
 * How the current cycle should be classified for this device.
 */
export type DeviceCurrentCycleClassify = "ENTIRE" | "FROMNOW"

/**
 * A human friendly tag for grouping associated devices together.
 */
export type DeviceTag = string

/**
 * The type of Device
 */
export type DeviceType = "MTConnectDevice" | "Line" | "Station" | "Cell"

/**
 * The configured realtime and historical performance parameters for a device.
 */
export type IDevicePerformanceParameters = {
  realtimeDataItems: IDeviceDataItem[],
  historicalDataItems: IDeviceDataItem[]
}

/**
 * Validate a Device UUID.
 * Note: Device UUIDs are not normal UUIDs!
 */
export function validateDeviceUUID(input: string): DeviceUUID {
  return validateString(input)
}

/**
 * Validate a Device Name
 */
export function validateDeviceName(input: string): DeviceName {
  return validateString(input)
}

/**
 * Validate a Device Allow Producing value.
 */
export function validateDeviceAllowProducing(input: boolean): DeviceAllowProducing {
  return validateBoolean(input)
}

/**
 * Validate a Device HasRealtimeData value.
 */
export function validateDeviceHasRealtimeData(input: boolean): DeviceHasRealtimeData {
  return validateBoolean(input)
}

/**
 * Validate a Device Enabled value
 */
export function validateDeviceEnabled(input: boolean): DeviceEnabled {
  return validateBoolean(input)
}

/**
 * Validate a Device Current Cycle Classify value.
 */
export function validateDeviceCurrentCycleClassify(input: string): DeviceCurrentCycleClassify {
  validateString(input)
  invariant(input === "ENTIRE" || input === "FROMNOW", "currentCycleClassify must be either FROMNOW or ENTIRE")
  return input
}

/**
 * Validate a Device Type value.
 */
export function validateDeviceType(input: string): DeviceType {
  validateString(input)
  invariant(
    input === "MTConnectDevice" || input === "Line" || input === "Station" || input === "Cell",
    "deviceType must be one of MTConnectDevice, Line, Station or Cell"
  )
  return input
}

/**
 * Validate a Device Tag
 */
export function validateDeviceTag(input: string): DeviceTag {
  return validateString(input)
}

/**
 * Validate a device performance parameters object.
 */
export const validateDevicePerformanceParameters: Validator<IDevicePerformanceParameters> = shapeOf({
  realtimeDataItems: arrayOf(validateDeviceDataItem),
  historicalDataItems: arrayOf(validateDeviceDataItem)
})

/**
 * Validates a Device.
 */
export const validateDevice: Validator<IDevice> = shapeOf({
  uuid: validateDeviceUUID,
  name: validateDeviceName,
  enabled: validateDeviceEnabled,
  allowProducing: validateDeviceAllowProducing,
  currentCycleClassify: validateDeviceCurrentCycleClassify,
  tags: arrayOf(validateDeviceTag),
  performanceParameters: validateDevicePerformanceParameters,
  deviceType: validateDeviceType,
  hasRealtimeData: validateDeviceHasRealtimeData
})
