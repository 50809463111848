// @flow
import {type UserID, validateUserID} from "../Auth/User"
import {type EmailAddress, type Validator, shapeOf, literal, union, validateEmailAddress} from "../Common"

/**
 * Represents either a registered user or email address
 * that is watching an alert.
 */
export type IAlertWatcher = AlertWatcherUser | AlertWatcherEmail

/**
 * A user watching an alert.
 */
export type AlertWatcherUser = {
  type: "USER",
  value: UserID
}

/**
 * An email address watching an alert.
 */
export type AlertWatcherEmail = {
  type: "EMAIL",
  value: EmailAddress
}

/**
 * Validate an AlertWatcherUser.
 */
export const validateAlertWatcherUser: Validator<AlertWatcherUser> = shapeOf({
  type: literal("USER"),
  value: validateUserID
})

/**
 * Validate an AlertWatcherEmail.
 */
export const validateAlertWatcherEmail: Validator<AlertWatcherEmail> = shapeOf({
  type: literal("EMAIL"),
  value: validateEmailAddress
})

/**
 * Validates an AlertWatcher
 */
export const validateAlertWatcher: Validator<IAlertWatcher> = union(validateAlertWatcherUser, validateAlertWatcherEmail)
