// @flow
import {type Username, validateUsername} from "../Auth/User"
import {type ISOTimestamp, validateISOTimestamp} from "../Time"
import {type ITicketWatcher, validateTicketWatcher} from "./TicketWatcher"
import {type ITicketDevice, validateTicketDevice} from "./TicketDevice"
import {type ITicketComment, validateTicketComment} from "./TicketComment"
import {type ITicketLink, validateTicketLink} from "./TicketLink"
import {type TicketTypeID, validateTicketTypeID} from "./TicketType"
import {type UUID, validateUUID, validateString, invariant, type Validator, shapeOf, optional, arrayOf} from "../Common"

/**
 * Represents a support or maintenance ticket.
 */
export type ITicket = {
  id: TicketID,
  creatorId: ?Username,
  assigneeId: ?Username,
  updatorId: ?Username,
  type: TicketTypeID[],
  status: TicketStatus,
  title: TicketTitle,
  description: TicketDescription,
  created: ISOTimestamp,
  watchers: ITicketWatcher[],
  devices: ITicketDevice[],
  links: ITicketLink[],
  comments: ITicketComment[]
}

/**
 * A unique id for a ticket.
 */
export type TicketID = UUID

/**
 * The status of a ticket.
 */
export type TicketStatus = "OPEN" | "IN_PROGRESS" | "CLOSED"

/**
 * The title for a ticket.
 */
export type TicketTitle = string

/**
 * A short text only description of a ticket.
 */
export type TicketDescription = string

/**
 * Validates a Ticket ID.
 */
export function validateTicketID(input: string): TicketID {
  return validateUUID(input)
}

/**
 * Validates a Ticket Status
 */
export function validateTicketStatus(input: string): TicketStatus {
  invariant(
    input === "OPEN" || input === "IN_PROGRESS" || input === "CLOSED",
    "Expected ticket status to be one of OPEN, IN_PROGRESS or CLOSED."
  )
  return input
}

/**
 * Validates a Ticket Title.
 */
export function validateTicketTitle(input: string): TicketTitle {
  return validateString(input)
}

/**
 * Validates a Ticket Description.
 */
export function validateTicketDescription(input: string): TicketDescription {
  return validateString(input)
}

export const validateTicket: Validator<ITicket> = shapeOf({
  id: validateTicketID,
  creatorId: optional(validateUsername),
  assigneeId: optional(validateUsername),
  updatorId: optional(validateUsername),
  type: arrayOf(validateTicketTypeID),
  status: validateTicketStatus,
  title: validateTicketTitle,
  description: validateTicketDescription,
  created: validateISOTimestamp,
  watchers: arrayOf(validateTicketWatcher),
  devices: arrayOf(validateTicketDevice),
  links: arrayOf(validateTicketLink),
  comments: arrayOf(validateTicketComment)
})
