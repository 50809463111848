// @flow
import {type Validator, type Color, validateString, validateColor, validateBoolean, shapeOf} from "../Common"

/**
 * Represents a type of `Ticket`.
 */
export type ITicketType = {
  id: TicketTypeID,
  value: TicketTypeValue,
  enabled: TicketTypeEnabled,
  color: TicketTypeColor
}

/**
 * Unique ID for a ticket.
 */
export type TicketTypeID = string

/**
 * The value associated with this ticket type.
 * @todo what does this value actually represent?
 */
export type TicketTypeValue = string

/**
 * Whether the ticket type is enabled or not.
 */
export type TicketTypeEnabled = boolean

/**
 * The color associated with the ticket type.
 */
export type TicketTypeColor = Color

/**
 * Validates a Ticket Type ID.
 */
export function validateTicketTypeID(input: string): TicketTypeID {
  return validateString(input)
}

/**
 * Validates a Ticket Type Value.
 */
export function validateTicketTypeValue(input: string): TicketTypeValue {
  return validateString(input)
}

/**
 * Validates a Ticket Type Enabled value.
 */
export function validateTicketTypeEnabled(input: boolean): TicketTypeEnabled {
  return validateBoolean(input)
}

/**
 * Validates a Ticket Type Color.
 */
export function validateTicketTypeColor(input: string): TicketTypeColor {
  return validateColor(input)
}

/**
 * Validates a Ticket Type.
 */
export const validateTicketType: Validator<ITicketType> = shapeOf({
  id: validateTicketTypeID,
  value: validateTicketTypeValue,
  enabled: validateTicketTypeEnabled,
  color: validateTicketTypeColor
})
