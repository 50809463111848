// @flow
import {invariant, type Validator, validateString, shapeOf} from "../Common/Validation"
import {type Units, validateUnits} from "./Units"

/**
 * An available metric.
 */
export type IMetric = {
  id: MetricID,
  name: MetricName,
  description: MetricDescription,
  units: Units
}

/**
 * The ID of a particular metric.
 */
export type MetricID =
  | "duration_sum"
  | "total_part_count"
  | "component_usage_duration"
  | "percentage_per_state"
  | "staffed_utilization"
  | "net_utilization"
  | "utilization"
  | "cycle_time"
  | "in_cut_duration"
  | "availability"
  | "quality"
  | "performance"
  | "oee"

/**
 * The name of a metric.
 */
export type MetricName = string

/**
 * A description for a metric.
 */
export type MetricDescription = string

/**
 * Validates a Metric ID.
 */
export function validateMetricID(input: string): MetricID {
  invariant(
    input === "duration_sum" ||
      input === "total_part_count" ||
      input === "component_usage_duration" ||
      input === "percentage_per_state" ||
      input === "staffed_utilization" ||
      input === "net_utilization" ||
      input === "utilization" ||
      input === "cycle_time" ||
      input === "in_cut_duration" ||
      input === "availability" ||
      input === "quality" ||
      input === "performance" ||
      input === "oee",
    "Unknown Metric ID."
  )

  return input
}

/**
 * Validates a Metric Name.
 */
export const validateMetricName: Validator<MetricName> = validateString

/**
 * Validates a Metric Description.
 */
export const validateMetricDescription: Validator<MetricDescription> = validateString

/**
 * Validate a Metric.
 */
export const validateMetric: Validator<IMetric> = shapeOf({
  id: validateMetricID,
  name: validateMetricName,
  description: validateMetricDescription,
  units: validateUnits
})
