// @flow
import {type ISOTimestamp, validateISOTimestamp} from "../Time"
import {type AlertTagID, validateAlertTagID} from "./AlertTag"
import {type IAlertDevice, validateAlertDevice} from "./AlertDevice"
import {type PlantName, validatePlantName} from "../Plant"
import {type Validator, type UUID, validateUUID, validateString, shapeOf, arrayOf} from "../Common"

/**
 * Represents various kinds of alert.
 */
export type IAlert = {
  id: AlertID,
  name: AlertName,
  description: AlertDescription,
  tags: AlertTagID[],
  device: IAlertDevice,
  created: ISOTimestamp,
  plant: PlantName
}

/**
 * The name of a alert.
 */
export type AlertName = string

/**
 * The unique ID for an alert.
 */
export type AlertID = UUID

/**
 * The human friendly description of an alert.
 */
export type AlertDescription = string

/**
 * Validates an Alert ID
 */
export function validateAlertID(input: string): AlertID {
  return validateUUID(input)
}

/**
 * Validates an Alert Description.
 */
export function validateAlertDescription(input: string): AlertDescription {
  return validateString(input)
}

/**
 * Validates an Alert Description.
 */
export function validateAlertName(input: string): AlertName {
  return validateString(input)
}

/**
 * Validates an Alert.
 */
export const validateAlert: Validator<IAlert> = shapeOf({
  id: validateAlertID,
  name: validateAlertName,
  description: validateAlertDescription,
  tags: arrayOf(validateAlertTagID),
  device: validateAlertDevice,
  created: validateISOTimestamp,
  plant: validatePlantName
})
