// @flow
import {type Username, validateUsername} from "../Auth/User"
import {
  type UnixTimeMS,
  type IInterval,
  type DurationMS,
  validateUnixTimeMS,
  validateDurationMS,
  validateInterval
} from "../Time"
import {type Validator, type UUID, validateUUID, validateString, shapeOf} from "../Common"

/**
 * A comment (in a log book).
 */
export type ILogbookComment = {
  commentId: LogbookCommentID,
  author: Username,
  body: LogbookCommentBody,
  createdMillis: UnixTimeMS,
  duration: DurationMS,
  interval: IInterval
}

/**
 * A unique id for a comment.
 */
export type LogbookCommentID = UUID

/**
 * The body of a comment.
 */
export type LogbookCommentBody = string

/**
 * Validates a Logbook Comment ID.
 */
export function validateLogbookCommentID(input: string): LogbookCommentID {
  return validateUUID(input)
}

/**
 * Validates a Logbook Comment Body.
 */
export function validateLogbookCommentBody(input: string): LogbookCommentBody {
  return validateString(input)
}

/**
 * Validates a Logbook Comment.
 */
export const validateLogbookComment: Validator<ILogbookComment> = shapeOf({
  commentId: validateLogbookCommentID,
  author: validateUsername,
  body: validateLogbookCommentBody,
  createdMillis: validateUnixTimeMS,
  duration: validateDurationMS,
  interval: validateInterval
})
