// @flow
import {validateString, invariant, validateNumber} from "./Validation"

/**
 * A unique, URL-friendly alphanumeric identifier.
 */
// eslint-disable-next-line
export type UUID = string;

/**
 * An integer value.
 */
export type Integer = number

/**
 * A floating point number.
 */
export type Float = number

/**
 * A value measured in pixels.
 */
export type Pixels = number

/**
 * A valid HTML color, e.g `#FFFFFF` or `rgba(12, 34, 56, 0.75)`.
 */
export type Color = string

/**
 * A valid email address.
 */
export type EmailAddress = string

/**
 * Validate a UUID.
 */
export function validateUUID(input: string): UUID {
  validateString(input)
  invariant(/^([A-F0-9_-]+)$/i.test(input), "Expected a UUID.")
  return input
}

/**
 * Validate an Integer.
 */
export function validateInteger(input: number): Integer {
  validateNumber(input)
  invariant(Math.floor(input) === input, "Expected an integer.")
  return input
}

/**
 * Validate a floating point number.
 */
export function validateFloat(input: number): Float {
  validateNumber(input)
  return input
}

/**
 * Validate a color.
 */
export function validateColor(input: string): Color {
  validateString(input)
  invariant(
    /(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/i.test(
      input
    ),
    "Expected a valid color."
  )
  return input
}

/**
 * Validate an email address.
 * Note: We use the simplest possible check here,
 * the only true way to validate an email address is to send
 * an email to it.
 */
export function validateEmailAddress(input: string): EmailAddress {
  validateString(input)
  invariant(/^(.+)@(.+)$/.test(input), "Expected a valid email address.")
  return input
}
