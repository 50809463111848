// @flow
import {type IShift, validateShift} from "./Shift"
import {type Validator, shapeOf, arrayOf} from "../Common"

/**
 * Represents a working day at a plant.
 * Workdays consist of one or more shifts.
 */
export type IWorkday = {
  shifts: IShift[]
}

/**
 * Validate a Workday.
 */
export const validateWorkday: Validator<IWorkday> = shapeOf({
  shifts: arrayOf(validateShift)
})
