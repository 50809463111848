// @flow
import {type IInterval, validateInterval} from "./Time"
import {type Validator, shapeOf, validateString} from "../Common"

/**
 * A shift being worked at a plant.
 */
export type IShift = {
  name: ShiftName,
  interval: IInterval
}

/**
 * The human-friendly name of a shift.
 */
export type ShiftName = string

/**
 * Validate a shift name.
 */
export function validateShiftName(input: string): ShiftName {
  return validateString(input)
}

/**
 * Validate a Shift.
 */
export const validateShift: Validator<IShift> = shapeOf({
  name: validateShiftName,
  interval: validateInterval
})
