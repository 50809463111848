// @flow

import {validateString, invariant} from "../Common"

/**
 * The name of particular permission in the system.
 */
export type PermissionName = string

/**
 * Validate a permission name.
 */
export function validatePermissionName(input: string): PermissionName {
  validateString(input)

  invariant(/^([\w|_|-]+):([\w|_|-]+)(:([\w|_|-]+))?$/.test(input), "Expected a valid permission name")
  return input
}
