// @flow
import {type Validator, shapeOf, validateNumber} from "../Common"

/**
 * A paginated request
 */
export type PaginationRequest = {
  pageSize?: ?number,
  page: number
}

/**
 * A paginated response
 */
export type PaginationResponse = {
  /* The number of items in total */
  totalItems: number,
  /* The number of items in a page */
  pageSize: number,
  /* The current page number starting at 1 */
  page: number,
  /* The actual response payload */
  data: Array<any>
}

/** Validate the pagination request object */
export const validatePaginationRequest: Validator<PaginationRequest> = shapeOf({
  page: validateNumber
})
