// @flow

import {invariant} from "../Common/Validation"

/**
 * Standard Units
 * From the spec:
 * The following lists the units that are defined as the standard unit of measure for each type
 * of DataItem. All SAMPLE type data items MUST report data values in standard units.
 */
export type Units =
  // Amps
  | "AMPERE"
  // Degrees Celsius
  | "CELSIUS"
  // A counted event
  | "COUNT"
  // Sound Level
  | "DECIBEL"
  // Angle in degrees
  | "DEGREE"
  // Angular degrees per second
  | "DEGREE/SECOND"
  // Angular acceleration in degrees per second squared
  | "DEGREE/SECOND^2"
  // Frequency measured in cycles per second
  | "HERTZ"
  // A measurement of energy
  | "JOULE"
  // Kilograms
  | "KILOGRAM"
  // Liters
  | "LITER"
  // Liters per second
  | "LITER/SECOND"
  // Measurement of Tilt
  | "MICRO_RADIAN"
  // Millimeters
  | "MILLIMETER"
  // Millimeters per second
  | "MILLIMETER/SECOND"
  // Acceleration in millimeters per second squared
  | "MILLIMETER/SECOND^2"
  // A point in space identified by X, Y, and Z positions and represented by a space-delimited set of numbers each expressed in millimeters.
  | "MILLIMETER_3D"
  // Force in Newtons
  | "NEWTON"
  // Torque, a unit for force times distance.
  | "NEWTON_METER"
  // Measure of Electrical Resistance
  | "OHM"
  // Pressure in Newtons per square meter
  | "PASCAL"
  // Measurement of Viscosity
  | "PASCAL_SECOND"
  // Percentage
  | "PERCENT"
  // A measure of the acidity or alkalinity of a solution
  | "PH"
  // Revolutions per minute
  | "REVOLUTION/MINUTE"
  // A measurement of time.
  | "SECOND"
  // A measurement of Electrical Conductivity
  | "SIEMENS/METER"
  // Volts
  | "VOLT"
  // Volt-Ampere (VA)
  | "VOLT_AMPERE"
  // Volt-Ampere Reactive (VAR)
  | "VOLT_AMPERE_REACTIVE"
  // Watts
  | "WATT"
  // Measurement of electrical energy, equal to one Joule
  | "WATT_SECOND"
  // Non-standard measurement of time in milliseconds rather than seconds.
  | "MILLISECOND"
  // Non-standard percentage value represented as a number between 0 and 1.
  | "FRACTIONAL_PERCENT"

/**
 * An array containing all supported units.
 */
export const supportedUnits: Units[] = Object.freeze([
  "AMPERE",
  "CELSIUS",
  "COUNT",
  "DECIBEL",
  "DEGREE",
  "DEGREE/SECOND",
  "DEGREE/SECOND^2",
  "HERTZ",
  "JOULE",
  "KILOGRAM",
  "LITER",
  "LITER/SECOND",
  "MICRO_RADIAN",
  "MILLIMETER",
  "MILLIMETER/SECOND",
  "MILLIMETER/SECOND^2",
  "MILLIMETER_3D",
  "NEWTON",
  "NEWTON_METER",
  "OHM",
  "PASCAL",
  "PASCAL_SECOND",
  "PERCENT",
  "PH",
  "REVOLUTION/MINUTE",
  "SECOND",
  "SIEMENS/METER",
  "VOLT",
  "VOLT_AMPERE",
  "VOLT_AMPERE_REACTIVE",
  "WATT",
  "WATT_SECOND",
  "MILLISECOND",
  "FRACTIONAL_PERCENT"
])

/**
 * Native Units.
 * From the MTC Spec:
 * The nativeUnits attribute provides additional information about the original measured value
 * for a Data Entity reported by a piece of equipment. nativeUnits MAYbe specified to
 * provide additional information about the data if the units of the measured value supplied by the
 * piece of equipment differ from the value provided for that data when converted to standard units.
 */
export type NativeUnits =
  // A measure of Viscosity
  | "CENTIPOISE"
  // Rotational velocity in degrees per minute
  | "DEGREE/MINUTE"
  // Temperature in Fahrenheit
  | "FAHRENHEIT"
  // Feet
  | "FOOT"
  // Feet per minute
  | "FOOT/MINUTE"
  // Feet per second
  | "FOOT/SECOND"
  // Acceleration in feet per second squared
  | "FOOT/SECOND^2"
  // A point in space identified by X, Y, and Z positions and represented by a space-delimited set of numbers each expressed in feet.
  | "FOOT_3D"
  // Gallons per minute.
  | "GALLON/MINUTE"
  // Inches
  | "INCH"
  // Inches per minute
  | "INCH/MINUTE"
  // Inches per second
  | "INCH/SECOND"
  // Acceleration in inches per second squared
  | "INCH/SECOND^2"
  // A point in space identified by X, Y, and Z positions and represented by a space-delimited set of numbers each expressed in inches.
  | "INCH_3D"
  // A measure of torque in inch pounds.
  | "INCH_POUND"
  // A measurement of temperature
  | "KELVIN"
  // A measurement in kilowatt.
  | "KILOWATT"
  // Kilowatt hours which is 3.6 mega joules.
  | "KILOWATT_HOUR"
  // Measurement of volume of a fluid
  | "LITER"
  // Measurement of rate of flow of a fluid
  | "LITER/MINUTE"
  // Velocity in millimeters per minute
  | "MILLIMETER/MINUTE"
  // US pounds
  | "POUND"
  // Pressure in pounds per square inch (PSI).
  | "POUND/INCH^2"
  // Angle in radians
  | "RADIAN"
  // Velocity in radians per second
  | "RADIAN/SECOND "
  // Rotational acceleration in radian per second squared
  | "RADIAN/SECOND^2"
  // Velocity in radians per minute.
  | "RADIAN/MINUTE"
  // Rotational velocity in revolution per second
  | "REVOLUTION/SECOND"
  // Unsupported units
  | "OTHER"

/**
 * A list of all supported native units.
 */
export const supportedNativeUnits: NativeUnits[] = Object.freeze([
  "CENTIPOISE",
  "DEGREE/MINUTE",
  "FAHRENHEIT",
  "FOOT",
  "FOOT/MINUTE",
  "FOOT/SECOND",
  "FOOT/SECOND^2",
  "FOOT_3D",
  "GALLON/MINUTE",
  "INCH",
  "INCH/MINUTE",
  "INCH/SECOND",
  "INCH/SECOND^2",
  "INCH_3D",
  "INCH_POUND",
  "KELVIN",
  "KILOWATT",
  "KILOWATT_HOUR",
  "LITER",
  "LITER/MINUTE",
  "MILLIMETER/MINUTE",
  "POUND",
  "POUND/INCH^2",
  "RADIAN",
  "RADIAN/SECOND ",
  "RADIAN/SECOND^2",
  "RADIAN/MINUTE",
  "REVOLUTION/SECOND",
  "OTHER"
])

/**
 * Validate units.
 */
export function validateUnits(input: string): Units {
  invariant(
    input === "AMPERE" ||
      input === "CELSIUS" ||
      input === "COUNT" ||
      input === "DECIBEL" ||
      input === "DEGREE" ||
      input === "DEGREE/SECOND" ||
      input === "DEGREE/SECOND^2" ||
      input === "HERTZ" ||
      input === "JOULE" ||
      input === "KILOGRAM" ||
      input === "LITER" ||
      input === "LITER/SECOND" ||
      input === "MICRO_RADIAN" ||
      input === "MILLIMETER" ||
      input === "MILLIMETER/SECOND" ||
      input === "MILLIMETER/SECOND^2" ||
      input === "MILLIMETER_3D" ||
      input === "NEWTON" ||
      input === "NEWTON_METER" ||
      input === "OHM" ||
      input === "PASCAL" ||
      input === "PASCAL_SECOND" ||
      input === "PERCENT" ||
      input === "PH" ||
      input === "REVOLUTION/MINUTE" ||
      input === "SECOND" ||
      input === "SIEMENS/METER" ||
      input === "VOLT" ||
      input === "VOLT_AMPERE" ||
      input === "VOLT_AMPERE_REACTIVE" ||
      input === "WATT" ||
      input === "WATT_SECOND" ||
      input === "MILLISECOND" ||
      input === "FRACTIONAL_PERCENT",
    "Unsupported Units"
  )

  return input
}

/**
 * Validate native units.
 */
export function validateNativeUnits(input: string): NativeUnits {
  invariant(
    input === "CENTIPOISE" ||
      input === "DEGREE/MINUTE" ||
      input === "FAHRENHEIT" ||
      input === "FOOT" ||
      input === "FOOT/MINUTE" ||
      input === "FOOT/SECOND" ||
      input === "FOOT/SECOND^2" ||
      input === "FOOT_3D" ||
      input === "GALLON/MINUTE" ||
      input === "INCH" ||
      input === "INCH/MINUTE" ||
      input === "INCH/SECOND" ||
      input === "INCH/SECOND^2" ||
      input === "INCH_3D" ||
      input === "INCH_POUND" ||
      input === "KELVIN" ||
      input === "KILOWATT" ||
      input === "KILOWATT_HOUR" ||
      input === "LITER" ||
      input === "LITER/MINUTE" ||
      input === "MILLIMETER/MINUTE" ||
      input === "POUND" ||
      input === "POUND/INCH^2" ||
      input === "RADIAN" ||
      input === "RADIAN/SECOND " ||
      input === "RADIAN/SECOND^2" ||
      input === "RADIAN/MINUTE" ||
      input === "REVOLUTION/SECOND" ||
      input === "OTHER",
    "Unsupported Native Units"
  )
  return input
}
