// @flow
import {validateString, type Validator, shapeOf, invariant} from "../Common"

/**
 * A user or email address watching a ticket.
 */
export type ITicketLink = {
  type: TicketLinkType,
  status: TicketLinkStatus,
  value: TicketLinkValue
}

/**
 * A type of ticket link.
 */
export type TicketLinkType = string

export type TicketLinkStatus = "LINKING" | "LINKED" | "FAILED"

/**
 * The value of a ticket link.
 */
export type TicketLinkValue = string

/**
 * Validates a Ticket Link Type
 */
export function validateTicketLinkType(input: string): TicketLinkType {
  return validateString(input)
}

/**
 * Validates a Ticket Link Status
 */
export function validateTicketLinkStatus(input: string): TicketLinkStatus {
  invariant(
    input === "LINKING" || input === "LINKED" || input === "FAILED",
    "Expected ticket status to be one of LINKING, LINKED or FAILED"
  )
  return input
}

/**
 * Validates a Ticket Link Value
 */
export function validateTicketLinkValue(input: string): TicketLinkValue {
  return validateString(input)
}

/**
 * Validates a Ticket Link.
 */
export const validateTicketLink: Validator<ITicketLink> = shapeOf({
  type: validateTicketLinkType,
  status: validateTicketLinkStatus,
  value: validateTicketLinkValue
})
