// @flow

import {type IPlantState, type PlantStateID, validatePlantStateID, validatePlantState} from "./PlantState"

import {
  type IPlantActivity,
  type PlantActivityID,
  validatePlantActivityID,
  validatePlantActivity
} from "./PlantActivity"
import {validateBoolean, validateNumber, shapeOf, arrayOf, type Validator, validateObject, optional} from "../Common"

/**
 * A list of possible states and activities at a plant,
 * and the mappings between them.
 */
export type IPlantStateActivities = {
  activities: IPlantActivity[],
  mapping: IPlantStateMapping[],
  states: IPlantState[]
}

/**
 * Maps a PlantStateID to a list of activities.
 */
export type IPlantStateMapping = {
  stateId: PlantStateID,
  activities: IPlantActivityMapping[]
}

/**
 * Contains information about an activity when mapped to a state
 * (via `IPlantStateMapping`).
 */
export type IPlantActivityMapping = {
  id: PlantActivityID,
  rank: PlantActivityRank,
  forceComment: PlantActivityForceComment,
  userAction?: void | PlantActivityUserAction
}

/**
 * The rank of this activity.
 * @todo clarification
 */
export type PlantActivityRank = number

/**
 * Whether this activity requires the operator to supply a comment.
 * @deprecated
 */
export type PlantActivityForceComment = boolean

/**
 * The user action to invoke when the an activity is classified.
 */
export type PlantActivityUserAction = Object

/**
 * Validates an activity rank.
 * @todo This needs refinement.
 */
export function validatePlantActivityRank(input: number): PlantActivityRank {
  return validateNumber(input)
}

/**
 * Validates a force comment value.
 */
export function validatePlantActivityForceComment(input: boolean): PlantActivityForceComment {
  return validateBoolean(input)
}

/**
 * Validates a user action configuration for a plant state activity.
 */
export function validatePlantActivityUserAction(input: Object): PlantActivityUserAction {
  return validateObject(input)
}

/**
 * Validates a plant activity mapping.
 */
export const validatePlantActivityMapping: Validator<IPlantActivityMapping> = shapeOf({
  id: validatePlantActivityID,
  rank: validatePlantActivityRank,
  forceComment: validatePlantActivityForceComment,
  userAction: optional(validatePlantActivityUserAction)
})

/**
 * Validates a plant state mapping.
 */
export const validatePlantStateMapping: Validator<IPlantStateMapping> = shapeOf({
  stateId: validatePlantStateID,
  activities: arrayOf(validatePlantActivityMapping)
})

/**
 * Validates plant state activities.
 */
export const validatePlantStateActivities: Validator<IPlantStateActivities> = shapeOf({
  activities: arrayOf(validatePlantActivity),
  mapping: arrayOf(validatePlantStateMapping),
  states: arrayOf(validatePlantState)
})
