import {IDeviceDataItem} from "../Device"
import {SelectValueType, DurationType} from "./Common"
import {ConditionClassifyType, MessageClassifyType} from "./DeviceClassify"

type Props = {
  operator?: SelectValueType,
  isOpen: boolean,
  setOpen: (open: boolean) => unknown,
  setOperator: (newOperator: string) => unknown
}

export type FilterProps = {
  values?: string[],
  setValues: (newValue: string[]) => unknown
} & Props

export type ConditionTypeFilterProps = {
  dataItems?: IDeviceDataItem[],
  setDataItems: (newValue: IDeviceDataItem[]) => unknown
} & Props

export type ConditionProps = {
  condition: ConditionRule,
  updateSelf: Function,
  classifyName?: string,
  first?: boolean,
  singleCondition?: boolean,
  isTypeDataItemOnly?: boolean
}

export type ConditionRule = {
  conditionType: string,
  dataItem?: IDeviceDataItem,
  conditionOperation?: SelectValueType,
  conditionClassify?: ConditionClassifyType,
  message?: MessageClassifyType,
  value?: string,
  conditionValue?: string,
  duration?: DurationType,
  logicConnection?: SelectValueType,
  isOpen?: boolean,
  conditions?: ConditionRule[],
  conditionBetween?: {
    old: string,
    from: string,
    to: string,
    time: string
  }
}
