// @flow
import {validateString, validateNumber, shapeOf, type Validator} from "../Common"

/**
 * Represents the timezone of a Plant.
 */
export type ITimezone = {
  id: TimezoneID,
  currentOffsetMillis: TimezoneUTCOffset
}

/**
 * The standardized ID for a timezone, e.g. `Africa/Abidjan`
 */
export type TimezoneID = string

/**
 * The offset of a timezone from UTC in milliseconds.
 */
export type TimezoneUTCOffset = number

/**
 * Validates a timezone ID.
 * @todo implementation
 */
export function validateTimezoneID(input: string): TimezoneID {
  return validateString(input)
}

/**
 * Validates a timezone offset.
 * @todo implementation
 */
export function validateTimezoneUTCOffset(input: number): TimezoneUTCOffset {
  return validateNumber(input)
}

/**
 * Validates a timezone.
 */
export const validateTimezone: Validator<ITimezone> = shapeOf({
  id: validateTimezoneID,
  currentOffsetMillis: validateTimezoneUTCOffset
})
