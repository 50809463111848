// @flow
import {type UserID, validateUserID} from "../Auth/User"
import {type EmailAddress, type Validator, shapeOf, literal, union, validateEmailAddress} from "../Common"

/**
 * Represents either a registered user or email address
 * that is watching a ticket.
 */
export type ITicketWatcher = TicketWatcherUser | TicketWatcherEmail

/**
 * A user watching a ticket.
 */
export type TicketWatcherUser = {
  type: "USER",
  value: UserID
}

/**
 * An email address watching a ticket.
 */
export type TicketWatcherEmail = {
  type: "EMAIL",
  value: EmailAddress
}

/**
 * Validate an TicketWatcherUser.
 */
export const validateTicketWatcherUser: Validator<TicketWatcherUser> = shapeOf({
  type: literal("USER"),
  value: validateUserID
})

/**
 * Validate an TicketWatcherEmail.
 */
export const validateTicketWatcherEmail: Validator<TicketWatcherEmail> = shapeOf({
  type: literal("EMAIL"),
  value: validateEmailAddress
})

/**
 * Validates an TicketWatcher
 */
export const validateTicketWatcher: Validator<ITicketWatcher> = union(
  validateTicketWatcherUser,
  validateTicketWatcherEmail
)
