// @flow
import {type PlantStateID, type PlantActivityID, validatePlantStateID, validatePlantActivityID} from "../Plant"
import {validateBoolean, type Validator, shapeOf, optional, literal} from "../Common"
import {validateInterval, type IInterval} from "../Time"

/**
 * A cycle represents the state of a machine at a specific time interval.
 */
export type ICycleClassification = {
  type: "CycleClassification",
  value: ICycleClassificationValue,
  interval: IInterval
}

/**
 * Represents the classification for a particular cycle.
 */
export type ICycleClassificationValue = {
  state: PlantStateID,
  activity?: PlantActivityID,
  isManual?: CycleClassificationIsManual
}

/**
 * Indicates whether or not this was a manual classification.
 */
export type CycleClassificationIsManual = boolean

/**
 * Validates a cycle classification is manual value.
 */
export function validateCycleClassificationIsManual(input: boolean): CycleClassificationIsManual {
  return validateBoolean(input)
}

/**
 * Validate a cycle classification value.
 */
export const validateCycleClassificationValue: Validator<ICycleClassificationValue> = shapeOf({
  state: validatePlantStateID,
  activity: optional(validatePlantActivityID),
  isManual: optional(validateCycleClassificationIsManual)
})

/**
 * Validate a cycle classification.
 */
export const validateCycleClassification: Validator<ICycleClassification> = shapeOf({
  type: literal("CycleClassification"),
  value: validateCycleClassificationValue,
  interval: validateInterval
})
