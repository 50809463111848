// @flow
import {validateString, shapeOf, literal, type Validator} from "../Common"
import {validateInterval, type IInterval} from "../Time"

/**
 * Represents the kind of part a device is/was producing
 * at the associated time interval.
 */
export type IPartKind = {
  type: "PartKind",
  value: PartKindValue,
  interval: IInterval
}

/**
 * The name of a part kind being produced by a device.
 */
export type PartKindValue = string

/**
 * Validates a Part Kind Value.
 */
export function validatePartKindValue(input: string): PartKindValue {
  return validateString(input)
}

/**
 * Validate a Part Kind.
 */
export const validatePartKind: Validator<IPartKind> = shapeOf({
  type: literal("PartKind"),
  value: validatePartKindValue,
  interval: validateInterval
})
