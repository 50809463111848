// @flow
import {validateInteger, invariant, type Integer, validateNumber, validateString, validateObject} from "../Common"

/**
 * Timestamp represented as number of milliseconds
 * since the unix epoch.
 */
export type UnixTimeMS = Integer

/**
 * An ISO formatted timestamp.
 */
export type ISOTimestamp = string

/**
 * A time formatted as hours and minutes, e.g. 16:20.
 */
export type HoursMinutes = string

/**
 * A duration in milliseconds.
 */
export type DurationMS = number

/**
 * A named reporting period.
 * @todo clarification
 */
export type ReportingPeriod = "WORKDAY" | "SHIFT" | "PT1H" | "HOUR"

/**
 * A day of the week.
 */
export type Weekday = "SUNDAY" | "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY"

/**
 * A time range expressed in milliseconds since the unix epoch.
 */
export type IInterval = {
  startMillis: UnixTimeMS,
  endMillis: UnixTimeMS
}

/**
 * The maximum possible time in milliseconds since unix epoch.
 */
export const END_OF_TIME: UnixTimeMS = 253402300799000

/**
 * Validate a unix timestamp in milliseconds.
 */
export function validateUnixTimeMS(input: number): UnixTimeMS {
  validateInteger(input)
  invariant(input >= 0 && input <= END_OF_TIME, "Expected a value between 0 and 253402300799000")
  return input
}

const ISO_TIMESTAMP_REGEX =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

/**
 * Validate an ISO formatted timestamp.
 */
export function validateISOTimestamp(input: string): ISOTimestamp {
  validateString(input)
  invariant(ISO_TIMESTAMP_REGEX.test(input), "Expected valid ISO Timestamp")
  return input
}

const HOURS_MINUTES_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

/**
 * Validate hours and minutes.
 */
export function validateHoursMinutes(input: string): HoursMinutes {
  validateString(input)
  invariant(HOURS_MINUTES_REGEX.test(input), "Expected valid time (HH:MM).")
  return input
}

/**
 * Validate a duration in milliseconds.
 */
export function validateDurationMS(input: number): DurationMS {
  return validateNumber(input)
}

/**
 * Validate a reporting period name.
 */
export function validateReportingPeriod(input: string): ReportingPeriod {
  invariant(
    input === "WORKDAY" || input === "SHIFT" || input === "PT1H" || input === "HOUR",
    "Expected reporting period to be one of WORKDAY, SHIFT, PT1H or HOUR"
  )
  return input
}

/**
 * Validate a day of the week.
 */
export function validateWeekday(input: string): Weekday {
  invariant(
    input === "SUNDAY" ||
      input === "MONDAY" ||
      input === "TUESDAY" ||
      input === "WEDNESDAY" ||
      input === "THURSDAY" ||
      input === "FRIDAY" ||
      input === "SATURDAY",
    "Expected reporting period to be one of: SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY or SATURDAY"
  )
  return input
}

/**
 * Validate a time interval.
 */
export function validateInterval(input: {startMillis: number, endMillis: number}): IInterval {
  validateObject(input)
  validateUnixTimeMS(input.startMillis)
  validateUnixTimeMS(input.endMillis)
  invariant(input.endMillis >= input.startMillis, "Interval must end after the start.")
  return input
}
