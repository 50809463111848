import {ConditionRule} from "./Condition"

export type AlertConfigValue = {
  alertDefinitionName?: string,
  description?: string,
  alertName?: string,
  tags?: string[],
  conditions?: ConditionRule[],
  isOpen?: boolean
}
