// @flow
import {validateString, shapeOf, literal, type Validator} from "../Common"
import {validateInterval, type IInterval} from "../Time"

/**
 * Represents the process of part a device is/was producing
 * at the associated time interval.
 */
export type IPartProcess = {
  type: "PartProcess",
  value: PartProcessValue,
  interval: IInterval
}

/**
 * The name of a part process being produced by a device.
 */
export type PartProcessValue = string

/**
 * Validates a Part Process Value.
 */
export function validatePartProcessValue(input: string): PartProcessValue {
  return validateString(input)
}

/**
 * Validate a Part Process.
 */
export const validatePartProcess: Validator<IPartProcess> = shapeOf({
  type: literal("PartProcess"),
  value: validatePartProcessValue,
  interval: validateInterval
})
