// @flow
import {type IInterval, type ReportingPeriod, validateReportingPeriod, validateInterval} from "../Time"
import {type DeviceTag, type DeviceUUID, validateDeviceUUID, validateDeviceTag} from "../Device"
import {
  type UUID,
  type Validator,
  validateUUID,
  validateString,
  validateNumber,
  shapeOf,
  union,
  arrayOf,
  intersect
} from "../Common"

/**
 * Represents productivity targets which apply to one or more devices.
 */
export type IPlan = IInterval & {
  id: PlanID,
  name: PlanName,
  period: ReportingPeriod,
  scope: IPlanScope,
  targets: IPlanTarget
}

/**
 * The unique ID for a plan.
 */
export type PlanID = UUID

/**
 * The name of a plan.
 */
export type PlanName = string

/**
 * Indicates the devices a plan refers to.
 */
export type IPlanScope = {
  devices: PlanScopeDevice[]
}

/**
 * Indicates either a single device or group of devices associated
 * with a plan.
 */
// eslint-disable-next-line
export type PlanScopeDevice = $Exact<{uuid: DeviceUUID}> | $Exact<{tag: DeviceTag}>

/**
 * The target values for a plan.
 */
export type IPlanTarget = {
  utilization: PlanTargetUtilization,
  partCount: PlanTargetPartCount
}

/**
 * The planned target utilization.
 */
export type PlanTargetUtilization = number

/**
 * The planned target part count.
 */
export type PlanTargetPartCount = number

/**
 * Validates a Plan ID
 */
export function validatePlanID(input: string): PlanID {
  return validateUUID(input)
}

/**
 * Validates a Plan Name
 */
export function validatePlanName(input: string): PlanName {
  return validateString(input)
}

/**
 * Validates a Plan Target Utilization
 */
export function validatePlanTargetUtilization(input: number): PlanTargetUtilization {
  return validateNumber(input)
}

/**
 * Validates a Plan Target Part Count
 */
export function validatePlanTargetPartCount(input: number): PlanTargetPartCount {
  return validateNumber(input)
}

/**
 * Validates a device uuid or tag in a Plan Scope.
 */
export const validatePlanScopeDevice: Validator<PlanScopeDevice> = union(
  shapeOf({uuid: validateDeviceUUID}),
  shapeOf({tag: validateDeviceTag})
)

/**
 * Validates a Plan Scope.
 */
export const validatePlanScope: Validator<IPlanScope> = shapeOf({
  devices: arrayOf(validatePlanScopeDevice)
})

/**
 * Validates a Plan Target
 */
export const validatePlanTarget: Validator<IPlanTarget> = shapeOf({
  utilization: validatePlanTargetUtilization,
  partCount: validatePlanTargetPartCount
})

/**
 * Validates a Plan
 */
export const validatePlan: Validator<IPlan> = intersect(
  validateInterval,
  shapeOf({
    id: validatePlanID,
    name: validatePlanName,
    period: validateReportingPeriod,
    scope: validatePlanScope,
    targets: validatePlanTarget
  })
)
