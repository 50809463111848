// @flow
import {type Validator, validateString, validateObject, shapeOf, optional, lazy, arrayOf} from "../Common"

/**
 * Layouts are configurable trees of elements,
 * used to represent dashboards and operator panels.
 */
export type ILayout = {
  id: LayoutID,
  name: LayoutName,
  element: ILayoutElement
}

/**
 * A unique-to-the-plant, URL-friendly ID for a layout.
 */
export type LayoutID = string

/**
 * A human-friendly label for a layout.
 */
export type LayoutName = string

/**
 * An element within the layout.
 */
export type ILayoutElement = {
  id?: ?string,
  type: string,
  props: Object,
  children: ILayoutElement[]
}

/**
 * Validate a Layout ID
 */
export function validateLayoutID(input: string): LayoutID {
  return validateString(input)
}

/**
 * Validate a Layout Name
 */
export function validateLayoutName(input: string): LayoutName {
  return validateString(input)
}

/**
 * Validate a Layout Element.
 */
export const validateLayoutElement: Validator<ILayoutElement> = shapeOf({
  id: optional(validateString),
  type: validateString,
  props: optional(validateObject),
  children: lazy(() => arrayOf(validateLayoutElement))
})

/**
 * Validate a Layout
 */
export const validateLayout: Validator<ILayout> = shapeOf({
  id: validateLayoutID,
  name: validateLayoutName,
  element: validateLayoutElement
})
