// @flow
import {type IInterval, validateInterval} from "../Time"
import {shapeOf, type Validator, arrayOf} from "../Common"

/**
 * A contiguous list of events ordered by time.
 */
export type Timeline<T: ITimelineItem> = T[]

/**
 * A base interface for items which occur in a timeline.
 */
export type ITimelineItem = {
  interval: IInterval
}

/**
 * Validate an item in a timeline.
 */
export const validateTimelineItem: Validator<ITimelineItem> = shapeOf({
  interval: validateInterval
})

/**
 * Validate a timeline.
 */
export const validateTimeline: Validator<Timeline<*>> = arrayOf(validateTimelineItem)
