// @flow
import {type ISOTimestamp, type ShiftName, validateISOTimestamp, validateShiftName} from "../Time"
import {type DeviceUUID, type DeviceTag, validateDeviceUUID, validateDeviceTag} from "../Device"
import {type PartKindValue, validatePartKindValue} from "../Timeline/PartKind"
import {type Units, type MetricID, validateMetricID, validateUnits} from "../Metrics"
import {type Validator, shapeOf, validateString, union, literal, validateNumber, optional, arrayOf} from "../Common"

/**
 * Represents a performance target for a metric.
 */
export type ITarget = {
  name: TargetName,
  metric: MetricID,
  startWorkday: ISOTimestamp,
  endWorkday: ISOTimestamp,
  period: TargetPeriod,
  scope: TargetScope,
  targetValue: number,
  targetUnit: Units
}

/**
 * The name of a Target.
 */
export type TargetName = string

/**
 * The time period for the target.
 */
export type TargetPeriod = "YEAR" | "MONTH" | "WEEK" | "WORKDAY" | "SHIFT" | "HOUR"

/**
 * The scope for a target.
 */
export type TargetScope = {
  device_id?: DeviceUUID[],
  kind_id?: PartKindValue[],
  device_group?: DeviceTag[],
  shift?: ShiftName[]
}

export const validateTargetName: Validator<TargetName> = validateString

export const validateTargetPeriod: Validator<TargetPeriod> = union(
  literal("YEAR"),
  literal("MONTH"),
  literal("WEEK"),
  literal("WORKDAY"),
  literal("SHIFT"),
  literal("HOUR")
)

export const validateTargetScope: Validator<TargetScope> = shapeOf({
  device_id: optional(arrayOf(validateDeviceUUID)),
  kind_id: optional(arrayOf(validatePartKindValue)),
  device_group: optional(arrayOf(validateDeviceTag)),
  shift: optional(arrayOf(validateShiftName))
})

export const validateTarget: Validator<ITarget> = shapeOf({
  name: validateTargetName,
  metric: validateMetricID,
  startWorkday: validateISOTimestamp,
  endWorkday: validateISOTimestamp,
  period: validateTargetPeriod,
  scope: validateTargetScope,
  targetValue: validateNumber,
  targetUnit: validateUnits
})
