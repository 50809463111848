// @flow
import {type ITicketDevice, validateTicketDevice} from "./TicketDevice"
import {type ITicketWatcher, validateTicketWatcher} from "./TicketWatcher"
import {type HoursMinutes, validateHoursMinutes} from "../Time"
import {validateString, type Validator, shapeOf, arrayOf} from "../Common"
import {type TicketTypeID, validateTicketTypeID} from "./TicketType"
import {validateBoolean} from "../Common/Validation"

/**
 * A rule for ticket watchers.
 */
export type ITicketWatcherRule = {
  id: TicketWatcherRuleID,
  name: TicketWatcherRuleName,
  enabled: TicketWatcherRuleEnabled,
  type: TicketWatcherRuleType[],
  devices: ITicketDevice[],
  watchers: ITicketWatcher[],
  from: HoursMinutes,
  to: HoursMinutes
}

/**
 * The unique ID for a ticket watcher rule.
 */
export type TicketWatcherRuleID = string

/**
 * A name for a ticket watcher rule.
 */
export type TicketWatcherRuleName = string

/**
 * Whether the rule is enabled..
 */
export type TicketWatcherRuleEnabled = boolean

/**
 * The IDs of the ticket types for a ticket watcher rule.
 */
export type TicketWatcherRuleType = TicketTypeID

/**
 * Validates a Ticket Watcher Rule ID.
 */
export function validateTicketWatcherRuleID(input: string): TicketWatcherRuleID {
  return validateString(input)
}

/**
 * Validates a Ticket Watcher Rule Rule.
 */
export function validateTicketWatcherRuleName(input: string): TicketWatcherRuleName {
  return validateString(input)
}

/**
 * Validates a Ticket Watcher Rule enabled field.
 */
export function validateTicketWatcherRuleEnabled(input: boolean): TicketWatcherRuleEnabled {
  return validateBoolean(input)
}

/**
 * Validates a Ticket Watcher Rule Type.
 */
export function validateTicketWatcherRuleType(input: string): TicketWatcherRuleType {
  return validateTicketTypeID(input)
}

/**
 * Validates a Ticket Watcher Rule from time.
 */
export const validateTicketWatcherRuleFrom: Validator<HoursMinutes> = validateHoursMinutes

/**
 * Validates a Ticket Watcher Rule to time.
 */
export const validateTicketWatcherRuleTo: Validator<HoursMinutes> = validateHoursMinutes

/**
 * Validates a Ticket Watcher Rule.
 */
export const validateTicketWatcherRule: Validator<ITicketWatcherRule> = shapeOf({
  id: validateTicketWatcherRuleID,
  name: validateTicketWatcherRuleName,
  enabled: validateTicketWatcherRuleEnabled,
  type: arrayOf(validateTicketWatcherRuleType),
  devices: arrayOf(validateTicketDevice),
  watchers: arrayOf(validateTicketWatcher),
  from: validateTicketWatcherRuleFrom,
  to: validateTicketWatcherRuleTo
})
