// @flow

import {arrayOf, shapeOf, validateString} from "../Common"
import {PlantName, validatePlantName} from "./Plant"

export type IPlantSettings = {
  plant: PlantName,
  machineDownActivity: PlantSettingsMachineDownActivity,
  defaultComments: IPlantSettingsDefaultComment[]
}

export type IPlantSettingsDefaultComment = {
  type: PlantSettingsDefaultCommentType,
  comment: PlantSettingsDefaultCommentValue
}

export type PlantSettingsMachineDownActivity = string

export type PlantSettingsDefaultCommentType = string

export type PlantSettingsDefaultCommentValue = string

export function validateMachineDownActivity(input: String): PlantSettingsMachineDownActivity {
  return validateString(input)
}

export function validateDefaultCommentType(input: String): PlantSettingsDefaultCommentType {
  return validateString(input)
}

export function validateDefaultCommentValue(input: String): PlantSettingsDefaultCommentValue {
  return validateString(input)
}

export const validateDefaultComments: Validator<IPlantSettingsDefaultComment> = shapeOf({
  type: validateDefaultCommentType,
  comment: validateDefaultCommentValue
})

export const validatePlantSettings: Validator<IPlantSettings> = shapeOf({
  plantName: validatePlantName,
  machineDownActivity: validateMachineDownActivity,
  defaultComments: arrayOf(validateDefaultComments)
})
