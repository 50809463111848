// @flow
import {type UnixTimeMS, validateUnixTimeMS} from "../Time"
import {type IScheduleEvent, validateScheduleEvent} from "./ScheduleEvent"
import {type Validator, shapeOf, arrayOf, validateString, validateUUID} from "../Common"

/**
 * Represents a schedule.
 */
export type ISchedule = {
  id: ScheduleID,
  name: ScheduleName,
  scheduleEventConfig: IScheduleEvent[],
  validityStart: UnixTimeMS,
  validityEnd: UnixTimeMS
}

/**
 * The ID of a schedule
 */
export type ScheduleID = string

/**
 * The name of a schedule.
 */
export type ScheduleName = string

/**
 * Validates a Schedule ID.
 */
export function validateScheduleID(input: string): ScheduleID {
  return validateUUID(input)
}

/**
 * Validates a Schedule Name.
 */
export function validateScheduleName(input: string): ScheduleName {
  return validateString(input)
}

/**
 * Validates a Schedule.
 */
export const validateSchedule: Validator<ISchedule> = shapeOf({
  id: validateScheduleID,
  name: validateScheduleName,
  scheduleEventConfig: arrayOf(validateScheduleEvent),
  validityStart: validateUnixTimeMS,
  validityEnd: validateUnixTimeMS
})
