// @flow
import {validateString, shapeOf, literal, type Validator} from "../Common"
import {validateInterval, type IInterval} from "../Time"

/**
 * Represents the part instance a device is/was producing
 * at the associated time interval.
 */
export type IPartInstance = {
  type: "PartInstance",
  value: PartInstanceValue,
  interval: IInterval
}

/**
 * The name of a part instance being produced by a device.
 */
export type PartInstanceValue = string

/**
 * Validates a Part Instance Value.
 */
export function validatePartInstanceValue(input: string): PartInstanceValue {
  return validateString(input)
}

/**
 * Validate a Part Instance.
 */
export const validatePartInstance: Validator<IPartInstance> = shapeOf({
  type: literal("PartInstance"),
  value: validatePartInstanceValue,
  interval: validateInterval
})
